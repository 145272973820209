@import '../../sass\ configs/00-complete';

.buttonA {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    min-height: 32px;
    line-height: 1.57em;     //22px
    padding: 0.285em 1.071em;    //4px 15px
}

.primary {
    background: #1890FF;
    border: 1px solid #1890FF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    color: #FFFFFF;
}

.secondary {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
}

.terthiary {

}