@import '../../sass\ configs/00-complete.scss';

//  talkId,firstChars,secondChars, lang, firstAccount, secondAccount, firstDevice, secondDevice, createdRange, confirmBtn
.filterD {
    display: grid;
    grid-template-areas: 'firstChars secondChars . confirmBtn'
                                'createdRange createdRange . .';
    grid-template-columns: repeat(4,auto);
    column-gap: 2em;
    row-gap: 1em;
    padding: 24px 28px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px;

    @at-root .filterItem {
        display: flex;
        align-items: center;
        justify-content: stretch;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        @at-root .filterLabel {
            text-transform: capitalize;
            margin-right: 0.6em;
            color: rgba(0, 0, 0, 0.6);
            min-width: 16ch;
        }
        @at-root .filterValue {
            
        }
    }

    @at-root .talkId {
        grid-area: talkId;
        position: relative;
        z-index: 0;

        & .filterValue {
            height: 32px;
        }
    }

    @at-root .firstChars {
        grid-area: firstChars;
        position: relative;
        z-index: 2;
        & .filterValue {
            height: 32px;
            min-width: 10em;
         
        }
    }

    @at-root .lang {
        grid-area: lang;
        position: relative;
        z-index: 3;

        & .filterValue {
            height: 32px;
            width: 200px;
        }
    }

    @at-root .secondChars {
        grid-area: secondChars;
        position: relative;
        z-index: 2;

        
        & .filterValue {
            height: 32px;
            min-width: 10em;
        }
    }

    @at-root .firstAccount {
        grid-area: firstAccount;

        
        & .filterValue {
            height: 32px;
        
        }
    }

    @at-root .secondAccount {
        grid-area: secondAccount;

        
        & .filterValue {
            height: 32px;
        
        }
    }

    @at-root .firstDevice {
        grid-area: firstDevice;

        
        & .filterValue {
            height: 32px;
        
        }
    }

    @at-root .secondDevice {
        grid-area: secondDevice;

        
        & .filterValue {
            height: 32px;
        
        }
    }

    @at-root .createdRange {
        grid-area: createdRange;
        position: relative;
        z-index: 1;
        

        & .filterValue {
            display: flex;
            align-items: center;
            padding: 4px 12px 4px 12px;
        
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 2px;

         

            @at-root .dateBox {
                height: 100%;
                align-self: center;

                :global(.react-datepicker-wrapper){
                    height: 100%;

                    :global(.react-datepicker__input-container) {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        input {
                            border: none;
                            height: 90%;
                            color: gray;
                        }
                    }
                    :global(.react-datepicker__triangle){
                        display: none !important;
                    }
                }

            }

            @at-root .arrIcon {
                margin: 0 10px;
            }
            @at-root .calIcon {
                margin-left: 10px;
            }
        }
    }

    @at-root .confirmBtn {
        grid-area: confirmBtn;
        margin-left: auto;
    }


    @at-root .refreshTime {
        grid-area: refreshTime;

        
        & .filterValue {
            height: 32px;
        
        }
    }
}