@import '../../sass\ configs/00-complete.scss';




.sideBar {
    font-size: 10px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

    @at-root .linkPanel {
        width: 100%;
        padding-top: 2em;

        @at-root .linkItem {
            font-size: 1.5em;
            width: 100%;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            line-height: 1.7em;
    
            &:hover {
                background: lighten($color: (#E6F7FF), $amount: 4);
                box-shadow: inset -3px 0px 0px lighten($color: (#1890FF), $amount: 30);
            }


            @at-root .activeItem {
                box-shadow: inset -3px 0px 0px #1890FF;
                background: #E6F7FF;
                

                & .link {
                    color: #1890FF;
                }
            }

            @at-root .link {
                padding: 0.7em 3em;
                display: block;
                width: 100%;
                text-decoration: none;
                text-transform: capitalize;
                color: inherit;
            }
        }
    }
}