@import '../../sass\ configs/00-complete.scss';



// template,headerBox,content,main,sideBarBox



.template {
    font-size: 10px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;


    @at-root .headerBox {
       
    }

    @at-root .content {
        flex: 1;
        display: grid;
        grid-template-areas: 'sideBarBox main';
        grid-template-columns: 20em 1fr;
        align-items: stretch;

        @at-root .main {
          padding: 2em;
          background-color: lighten(gray,30);
        }

        @at-root .sideBarBox {
         
        }
    }
}