@import '../../sass\ configs/00-complete.scss';

.nav {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 4em 0.5em 2em;
    background-color: $hamed-bg-first;

    @at-root .logoBox {
        width: 20em;  
    }

    @at-root .menuBox {
        min-width: 13em;
    }
}